import "./App.scss";
import pawn from "./images/pawn1.png";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>chessmates.io</h1>
        <p>friendly collectible chess nfts</p>
        <img width="300" alt="pawn card" src={pawn} />
      </header>
    </div>
  );
};

export default App;
